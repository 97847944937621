/*
 * @Description: axios的封装
 * @Author: Jin🐾
 * @Website: https://www.ijusee.com
 * @GitHub: https://github.com/ElevenIjusee
 * @Date: 2023-02-22 09:51:55
 */
import axios from 'axios';
import QS from 'qs';
import router from '@/router';
// import store from "@/store/modules/userStore";

//这一步的目的是判断出当前是开发环境还是生成环境，方法不止一种，达到目的就行
// if(process.env.NODE_ENV=="development"){
//   baseURL=''
// }else{
//   baseURL=''
// }

// const userStore = store();

// 全局变量，记录是否正在执行刷新令牌任务
let isRefreshing = false;
// 重试请求队列，每一项都是一个待执行函数
let requestList: ((access_token: string) => void)[] = [];

const $axios = axios.create({
	timeout: 30000,
});

// axios.defaults.timeout = 30000;  // 设置超时
// axios.defaults.baseURL = 

// 添加请求拦截器
$axios.interceptors.request.use(
	config => {
		// 每次发送请求之前判断vuex中是否存在token        
		// 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
		// 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
		const token = localStorage.getItem('catTk');
		if (token) {
			// 已经登录成功，统一添加token
			config.headers.Authorization = `Bearer ${token}`
		}
		// token && (config.headers.Authorization = token);
		return config; // 在最后必须 return config
	},
	error => {// do something with request error
		console.log(error) // for debug
		return Promise.reject(error)
	}
);

// 添加响应拦截器
$axios.interceptors.response.use(
	response => {
		const code = response.status;
		if ((code >= 200 && code < 300) || code === 304) {
			return Promise.resolve(response);
		} else {
			return Promise.reject(response);
		}
	},
	error => {   // 响应异常的全局拦截，可以在这里弹窗也可以在请求时弹窗
		
		if (!error.response) { return Promise.reject(error) };

		const { config } = error;  // 获取请求内信息，方便一会获取新token后重新请求

		if (error.response.status) {
			switch (error.response.status) {
				case 401:
					if (!isRefreshing) { // 先判断是否刷新中
						// console.log('进入refresh逻辑');
						isRefreshing = true;

						const refreshToken = localStorage.getItem('catRTK');

						if (refreshToken) {
							return post("/api/auth/refreshToken", { refreshToken })
								.then((res: any) => {
									const { access_token } = res.data;
									localStorage.setItem("catTk", access_token);
									// 将新的token替换上去
									config.headers.Authorization = `Bearer ${access_token}`;
									// localStorage.setItem("catRTK", res.data.refresh_token);
									// token 刷新后将队列中的方法重新执行
									requestList.forEach((cb) => cb(access_token))
									requestList = [] // 重新请求完清空

									return $axios(config);
								})
								.catch((error) => {
									console.log('refreshToken失效');
									router.push("/introduce/login");
								})
								.finally(() => {
									// console.log('进入到finally')
									isRefreshing = false; // token刷新成功关闭刷新状态
								})
						} else {
							console.log('本地没有refreshToken');
							router.push("/introduce/login");
						}
					} else {	// 正在刷新token，返回一个未执行resolve的promise
						return new Promise((resolve) => {
							// console.log('进入Promise')
							// 将resolve放进队列，用一个函数形式来保存，等token刷新后直接执行
							requestList.push((access_token: string) => {
								config.headers.Authorization = `Bearer ${access_token}`
								resolve($axios(config))
							});
						});
					}
			}
			// switch (error.response.status) {
			// 	// 401: 未登录
			// 	// 未登录则跳转登录页面，并携带当前页面的路径
			// 	// 在登录成功后返回当前页面，这一步需要在登录页操作。                
			// 	case 401:
			// 		router.replace({
			// 			path: '/login',
			// 			query: {
			// 				redirect: router.currentRoute.value.fullPath,
			// 			}
			// 		});
			// 		break;
			// 	// 403 token过期
			// 	// 登录过期对用户进行提示
			// 	// 清除本地token和清空vuex中token对象
			// 	// 跳转登录页面                
			// 	case 403:
			// 		ElMessageBox.alert('登录过期，请重新登录', {
			// 			confirmButtonText: "确定",
			// 			type: "warning",
			// 			callback: (action: any) => {
			// 				// 清除token
			// 				localStorage.removeItem('catToken');
			// 				// store.commit('loginSuccess', null);
			// 				// 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面 
			// 				router.replace({
			// 					path: '/login',
			// 					query: {
			// 						redirect: router.currentRoute.value.fullPath,
			// 					}
			// 				});
			// 			}
			// 		});
			// 		break;
			// 	// 404请求不存在
			// 	case 404:
			// 		ElMessageBox.alert('网络请求不存在', {
			// 			confirmButtonText: "确定",
			// 			type: "warning",
			// 			callback: (action: any) => { }
			// 		});
			// 		break;
			// 	// 其他错误，直接抛出错误提示
			// 	default:
			// 		ElMessageBox.alert(error.response.data.message, {
			// 			confirmButtonText: "确定",
			// 			type: "warning",
			// 			callback: (action: any) => { }
			// 		});
			// }
		}
		return Promise.reject(error.response);
	}
);

const refreshForToken = async () => {
	return new Promise((resolve) => {
		if (!isRefreshing) {

			const refreshToken = localStorage.getItem('catRTK');

			if (refreshToken) {  // 判断本地是否有refreshToken，有则使用，没有则返回登录页
				post("/api/auth/refreshToken", { refreshToken })
					.then((res: any) => {
						localStorage.setItem("catTk", res.data.access_token);
						// localStorage.setItem("catRTK", res.data.refresh_token);
					})
					.catch((error) => {
						console.log('refreshToken失效');
						router.push("/introduce/login");
					})
					.finally(() => {
						isRefreshing = false;
					})
			} else {
				console.log('本地没有refreshToken');
				router.push("/introduce/login");
			}
		}
	})
};

const get = (url: string, params?: any) => {
	// return new Promise((resolve, reject) => {
	// 	$axios.get(url, {
	// 		params: params
	// 	})
	// 		.then(res => {
	// 			resolve(res.data);
	// 		})
	// 		.catch(err => {
	// 			reject(err.data)
	// 		})
	// });
	return $axios.get(url, { params: params });
};
const post = (url: string, params?: any, headers?: any) => {
	// 	return new Promise((resolve, reject) => {
	// 		$axios.post(url, QS.stringify(params), headers)
	// 			.then(res => {
	// 				console.log(1)
	// 				resolve(res.data);

	// 			})
	// 			.catch(err => {
	// 				console.log(2)
	// 				reject(err.data)
	// 			})
	// 	});
	return $axios.post(url, QS.stringify(params), headers);
};

export default { get, post }

