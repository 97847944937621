import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import axios from 'axios'
import pinia from '@/store'

const app = createApp(App);
// app.use(store).use(router).use(createPinia()).mount('#app');
app.use(router).use(pinia).mount('#app');
// appa.config.globalProperties.$axios = axios;
