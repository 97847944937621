import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from "@/store/modules/userStore";
import { storeToRefs } from "pinia";
import nProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { toRaw } from 'vue';
import $axios from '@/api/api';

// 顶部进度条插件
nProgress.configure({
  showSpinner: false
});

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'homePage',
    component: () => import('@/views/HomePage/HomePage.vue'),
    meta: {
      title: 'J先生的猫窝窝(个人学习设计)'
    }
  },
  {
    path: '/introduce',
    // path: JSON.stringify('/是J先生不是喵先生'),
    name: 'introduce',
    component: () => import('@/views/PersonIntroducePage/Introduce.vue'),
    meta: {
      title: '个喵介绍'
    },
    children: [
      {
        path: '/introduce/login',
        name: 'login',
        component: () => import('@/views/PersonIntroducePage/BackgroundLogin.vue'),
        meta: {
          title: '进入猫窝'
        }
      }
    ]
  },
  {
    path: '/mobileTip',
    name: 'mobileTip',
    component: () => import('@/views/OtherPage/MobilePage.vue'),
    meta: {
      title: '请换用PC访问'
    }
  },
  // {
  //   // path: '/:error*',
  //   path: '/:path(.*)',
  //   name: '404',
  //   meta: {
  //     title: '猫咪去哪了？？？'
  //   },
  //   component: () => import('@/views/OtherPage/404Page.vue')
  // },
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/layout/LayoutIndex.vue'),
    children: [
      {
        path: '/projectDisplay/projectA1',
        name: 'projectA1',
        component: () => import('@/views/ProjectIndexPage/ProjectItem/PolygonSelection/PolygonSelection.vue'),
        meta: {
          title: 'C++跟js计算多边形框选速度对比',
        }
      }
    ]
  },
];

// 404的静态路由,如果提前加会导致addrouter未完成前跳转到404.所以放到最后动态添加
const notFoundRouter = {
  path: '/:error*',
  name: '404',
  meta: {
    title: '猫咪去哪了？？？'
  },
  component: () => import('@/views/OtherPage/404Page.vue'),
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// 路由全局拦截,会在每次触发路由跳转时触发
router.beforeEach((to, from, next) => {  // to要求的路由，from当前路由，next放行的意思

  nProgress.start() //进度条插件

  const userStore = store(); // pinia模块,这里如果放到函数外面的全局去挂载会报错，因为pinia还未完成初始化

  // 判断是否是移动端，如果为移动端则跳转到移动端提示页面
  if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) && !userStore.isMobile) {
    userStore.isMobile = true;
    next({ path: '/mobileTip' });
  };

  if (to.name === 'homePage' || to.name === 'introduce' || to.name === 'mobileTip' || to.path === '/introduce/login') {
    console.log('前往无需验证的页面')
    next()
  } else {
    if (!userStore.isSetRouter) {
      const userToken = localStorage.getItem("catToken");
      if (userToken) {
        $axios
          .get("/api/users/findAdminByToken")
          .then((res: any) => {
            for (let item of res.data.routerData.data) {
              let tempObj = Object.assign({}, item); // assigin方法可以克隆或者合并对象
              tempObj.component = () => require.ensure([], (require) => require(`@/views${item.component}.vue`));

              userStore.userRouter.push(tempObj);  //先在store做个备份，但是现在没有用到
              router.addRoute("layout", tempObj);  //第一个参数指定要添加成为哪个路由的子路由
            };

            router.addRoute(notFoundRouter);
            userStore.isSetRouter = true;
            // 确保addRoutes()时动态添加的路由已经被完全加载上去。刚刚addRoutes()就立刻访问被添加的路由，然而此时addRoutes()没有执行结束，因而找不到刚刚被添加的路由导致白屏或者404页面
            // replace: true只是一个设置信息，告诉VUE本次操作后，不能通过浏览器后退按钮，返回前一个路由。
            // 其实next({ ...to })的执行很简单，它会判断：如果参数to不能找到对应的路由的话，就再执行一次beforeEach((to, from, next)直到其中的next({ ...to})能找到对应的路由为止。
            next({ ...to, replace: true });
          })
          .catch((error: any) => {
            // usrStore,value =
            console.log(error);
          });
      }
    } else {
      next()
    }
  }

  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title as string;
  }

  // 当需要从‘from’跳转至‘to’时，路由守卫会监控到这一举动，若不执行next()，则相当于没有放行，会依然留在from对应的路由。只有当执行了next()之后，才会进行跳转。
  // 只有next()是放行，其他的诸如：next('/logon') 、 next(to) 或者 next({ ...to, replace: true })都不是放行，而是：中断当前导航，执行新的导航
  // next('/logon')不是说直接去/logon'路由，而是中断（VUE中的中断就是此时不会执行router.afterEach(() => {}）这一次路由守卫的操作，又进入一次路由守卫，就像嵌套一样，一层路由守卫，然后又是一层路由守卫，此时路由守卫进入到第二层时，to.path已经不是/home了，这个时候才执行next()放行操作
  // next()
});

// 路由后置钩子
router.afterEach(() => {
  nProgress.done(true) //结束进度条插件
});

export default router

