/*
 * @Description: 储存用户登录信息以及动态路由的相关方法
 * @Author: Jin🐾
 * @Website: https://www.ijusee.com
 * @GitHub: https://github.com/ElevenIjusee
 * @Date: 2023-02-16 15:11:11
 */
import { defineStore } from 'pinia'
import { Ref, ref, computed } from 'vue';
import router from "@/router";

const userStore = defineStore('main', () => {  // 这里的'main'是作为id的唯一值使用，名字任意

  const userRouter: Ref = ref([]);  // 存放路由的数组
  const isSetRouter: Ref = ref(false); // 判断是否登录过
  const isMobile: Ref = ref(false); // 判断是否已经判断过一次为移动端，防止router死循环
  const isRefreshFalse: Ref = ref(false); // 判断refreshToken是否也已经失效，防止axios拦截器里面无限循环

  // 404的静态路由,如果提前加会导致addrouter未完成前跳转到404.所以放到最后动态添加
  // const notFoundRouter = {
  //   path: '/:error*',
  //   name: '404',
  //   meta: {
  //     title: '猫咪去哪了？？？'
  //   },
  //   component: () => import('@/layout/LayoutIndex.vue'),
  // };

  // 通过token设置路由数据
  // function setRouter() {
  //   const userToken = localStorage.getItem("catToken");

  //   if (userToken) {
  //     axios
  //       .get("/api/users/findAdminByToken", {
  //         headers: {
  //           // Authorization: userToken,
  //           authorization: `Bearer ${userToken}`,
  //         }
  //       })
  //       .then((res: any) => {
  //         for (const item of res.data.routerData.data) {
  //           let tempObj = {
  //             name: item.name,
  //             path: item.path,
  //             component: () => require.ensure([], (require) => require(`@/views${item.component}.vue`)),
  //           };
  //           userRouter.value.push(tempObj);
  //           router.addRoute("layout", tempObj);  //第一个参数指定要添加成为哪个路由的子路由
  //         }
  //         router.addRoute(notFoundRouter); //最后添加404路由
  //         console.log(router.getRoutes());
  //         isSetRouter.value = true;

  //         return new Promise((resolve, reject)=>{
  //           resolve(true)
  //         })
  //       })
  //       .catch((error: any) => {
  //         // usrStore,value =
  //         console.log('token已经过期');
  //       });
  //   }
  // };

  return { userRouter, isSetRouter, isMobile, isRefreshFalse }
});

export default userStore;